














































  import Vue from 'vue'
  import { Component } from 'nuxt-property-decorator'
  import { namespace } from 'vuex-class'
  const categories = namespace('categories')

  @Component({
    components: {
      OneCategoryItem: () => import('~/components/molecules/items/OneCategoryItem.vue'),
    },
  })
  export default class OneCmsCategoriesList extends Vue {
    name: string = 'one-cms-categories-list'
    activeSubmenu: number | boolean = false
    activeMenu: boolean = false
    childrenSubcategoryExist: boolean = false
    categoryOnFocus: string = ''

    @categories.Getter getCategory: any
    @categories.Getter sortedMainCategories: any

    submenuDelayHandler: any = null
    elementHeight: number = 0

    get categories(): Array<string> {
      return this.sortedMainCategories.map((category: string): string => this.getCategory(category))
    }

    showChildrenSubcategory(category: string) {
      const subCategoryChildren = this.getChildCategory(category).children[0]
      const subCategoryChildrenObject = subCategoryChildren
        ? this.getChildCategory(subCategoryChildren).parent
        : null
      this.childrenSubcategoryExist = subCategoryChildrenObject === category
      this.categoryOnFocus = category
    }

    checkChildCategoryLength(children: string): boolean {
      const category = this.getChildCategory(children)
      if (category && category.children) {
        return category.children.length > 0
      }
      return false
    }

    checkCategoryNameExist(children: string): string | null {
      const category = this.getChildCategory(children)

      if (category && category.name) {
        return category.name
      }
      return null
    }

    onSubmenuEnter(id: number) {
      clearTimeout(this.submenuDelayHandler)
      this.submenuDelayHandler = setTimeout(() => {
        if (!this.activeMenu) {
          this.$emit('mouse-over')
        }
        this.activeMenu = true
        this.activeSubmenu = id
      }, 150)
    }

    onSubmenuLeave(_id: string) {
      clearTimeout(this.submenuDelayHandler)
    }

    activeMenuClose() {
      this.activeMenu = false
      this.activeSubmenu = false
    }

    getChildCategory(id: string) {
      return this.getCategory(id)
    }

    mouseLeave() {
      clearTimeout(this.submenuDelayHandler)
      this.$emit('mouse-leave')
      this.activeMenuClose()
    }

    getCategoryLevel(id: string, isChild: boolean = false) {
      let categoryLevel = this.$routes.getCategoryPath(id)?.split('/').length || 0
      categoryLevel = categoryLevel >= 1 ? categoryLevel - 1 : 0
      categoryLevel = isChild ? categoryLevel + 1 : categoryLevel
      return categoryLevel
    }
  }
